import { FloatButton, Table, Tag } from "antd";
import api from "http";
import React, { useEffect, useState } from "react";
import { numberFormat } from "util/NumberFormat";
import dateStringFormat from "util/dateStringFormat";

export default function OrdersDan() {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  useEffect(() => {
    setLoading(true);
    api
      .post("get-orders-noconfirm")
      .then(function (res) {
        if (res.status === 200) {
          setProduct(res.data);
        }
        console.table(res.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const columns = [
    {
      title: "Sana",
      dataIndex: "sana",
      key: "date",
      render: (_, rec) => {
        return dateStringFormat(rec.sana);
      },
    },
    {
      title: "Do'kon nomi",
      dataIndex: "d_name",
      key: "dukon_name",
    },
    {
      title: "Summa",
      dataIndex: "summa",
      key: "summa",
      render: (_, record) => {
        return numberFormat(record.summa);
      },
    },
    {
      title: "%",
      dataIndex: "chegirma",
      key: "chegirma",
      render: (_, ren) => {
        return <Tag color="geekblue">{ren.chegirma}%</Tag>;
      },
    },
    {
      title: "Sof summa",
      dataIndex: "total_skidka",
      key: "total_skidka",
      render: (_, record) => {
        return numberFormat(record.total_skidka);
      },
    },
    {
      title: "To'lov turlari",
      children: [
        {
          title: "Naqd",
          dataIndex: "naqd",
          key: "naqd",
          render: (_, record) => {
            return numberFormat(record.naqd);
          },
        },
        {
          title: "Bank",
          dataIndex: "plastik",
          key: "plastik",
          render: (_, record) => {
            return numberFormat(record.plastik);
          },
        },
        {
          title: "Karta",
          dataIndex: "karta",
          key: "karta",
          render: (_, record) => {
            return numberFormat(record.karta);
          },
        },
        {
          title: "Qolgan qarzi",
          dataIndex: "qarz",
          key: "qarz",
          render: (_, record) => {
            return numberFormat(record.qarz);
          },
        },
      ],
    },
    {
      title: "Mas'ul agent",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Eltuvchi",
      dataIndex: "eltuvchi_name",
      key: "eltuvchi_name",
    },
    {
      title: "Xolati",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "done") {
          return <Tag color="#87d068">Bajarilgan</Tag>;
        } else {
          return <Tag color="red">!</Tag>;
        }
      },
    },
    // {
    //   title: "Amallar",
    //   key: "operation",
    //   render: (_, record) => (
    //     <Space direction="horizontal">
    //       {/*<Link to={`${record.id}`} state={{data: record}}>Batafsil</Link>*/}
    //       <OrdersItem record={record} />
    //       <OrdersTransfer ordersData={record} />
    //       {/* <OrdersTransferUpdate ordersData={record} /> */}
    //     </Space>
    //   ),
    // },
  ];
  let column = columns.map((item) => {
    return Object.assign(
      {
        sorter: (a, b) => {
          // a.dataIndex.length - b.dataIndex.length
          if (a[item.dataIndex] > b[item.dataIndex]) return 1;
          if (a[item.dataIndex] === b[item.dataIndex]) return 0;
          if (a[item.dataIndex] < b[item.dataIndex]) return -1;
        },
        sortDirections: ["descend", "ascend"],
      },
      item
    );
  });

  return (
    <div>
      <h1>Bajarilgan buyurtmalar</h1>
      <Table
        rowKey={(row) => row.id}
        columns={column}
        pagination={{
          position: ["topLeft"], //  pageSize: "8",
        }}
        scroll={{
          x: 1124,
        }}
        dataSource={product}
        loading={loading}
      />
      <FloatButton.BackTop type="primary" />
    </div>
  );
}
